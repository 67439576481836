import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, ConflictErrorCode, WebUrlQuery } from '@spinach-shared/types';
import { CELLO_COOKIE_NAME, UTM_COOKIE_NAME } from '@spinach-shared/utils';
import { getWebAppHost } from '@spinach-shared/utils';

import { postVerifyGoogleWithCode } from '../../apis';
import { useExperienceTracking, useGlobalModal, useGlobalRouting } from '../../hooks';
import { AnonymousUserTracker } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { FYI, FYIState } from '../common';

export function VerifyGoogleCode() {
    const [params] = useSearchParams();
    const [, setGlobalModal] = useGlobalModal();
    const { routeToVerify } = useGlobalRouting();
    const track = useExperienceTracking();

    useEffect(() => {
        async function verifyCode() {
            const code = params.get(WebUrlQuery.Code);
            if (!code) {
                throw new Error('No code provided');
            }
            let stateString = params.get(WebUrlQuery.State) ?? undefined;

            if (stateString) {
                try {
                    const decodedState = atob(stateString);
                    const qp = new URLSearchParams(decodedState);
                    const ucc = qp.get(WebUrlQuery.UCC) ?? undefined;
                    const utmSource = qp.get(UTM_COOKIE_NAME) ?? undefined;
                    const timezone = qp.get(WebUrlQuery.Timezone) ?? undefined;

                    if (ucc) {
                        const expires = new Date();
                        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
                        document.cookie = `${CELLO_COOKIE_NAME}=${ucc};expires=${expires.toUTCString()};path=/;domain=.spinach.io`;
                    }
                    if (utmSource) {
                        const expires = new Date();
                        expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
                        const rawValue = JSON.parse(utmSource);
                        const utmSourceFromRaw = rawValue[UTM_COOKIE_NAME];
                        if (utmSourceFromRaw) {
                            document.cookie = `${UTM_COOKIE_NAME}=${JSON.stringify(
                                utmSourceFromRaw
                            )};expires=${expires.toUTCString()};path=/;domain=.spinach.io`;
                        }
                    }
                    if (!timezone) {
                        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        stateString = btoa(`${stateString}&${WebUrlQuery.Timezone}=${userTimezone}`);
                    }
                } catch (error) {}
            } else {
                const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                stateString = btoa(`?${WebUrlQuery.Timezone}=${userTimezone}`);
            }

            try {
                const response = await postVerifyGoogleWithCode(code, stateString);
                if (response?.user) {
                    const validatedUser = new ClientUser(response.user);

                    AnonymousUserTracker.trackUser(response.user._id);
                    AnonymousUserTracker.trackEvent(ClientEventType.UserGoogleCodeVerifySuccess);
                    await track(ClientEventType.UserEmailGoogleVerifyCodeSuccessBackend, {
                        ...validatedUser.toUserIdentityPayload(),
                        IsNewUser: response.isNewUser,
                    });

                    if (response.isNewUser) {
                        TagManager.trackSignUp(validatedUser);
                        if (validatedUser.isScribeOnlyUser) {
                            TagManager.trackGoogleCodeScribeSignup(validatedUser);
                        }
                    }

                    setGlobalModal(null);

                    /** @NOTE - we want to ensure that the TagManager tracking fires before redirecting */
                    setTimeout(() => {
                        window.location.href = `${getWebAppHost()}${window.location.search}`;
                    }, 300);
                } else if (response.isSsoRequired) {
                    routeToVerify(
                        undefined,
                        new URLSearchParams({
                            [WebUrlQuery.SSO]: 'true',
                        })
                    );
                } else if (response.errorCode === ConflictErrorCode.UserAlreadyLoggedInWithMicrosoft) {
                    const params = new URLSearchParams();
                    params.set(WebUrlQuery.AuthErrorCode, ConflictErrorCode.UserAlreadyLoggedInWithMicrosoft);
                    routeToVerify({ replace: true }, params);
                }
            } catch (error) {
                routeToVerify();
            }
        }

        verifyCode();
    }, [params.get(WebUrlQuery.Code)]);

    return <FYI state={FYIState.Loading} />;
}
