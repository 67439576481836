import { Box } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost, isProductionStage } from '@spinach-shared/utils';

import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import { ReactComponent as AccountIcon } from '../../../assets/sidebar/account_icon.svg';
import { ReactComponent as AdminIcon } from '../../../assets/sidebar/admin_icon.svg';
import { ReactComponent as AskSpinachIcon } from '../../../assets/sidebar/ask-spinach_icon.svg';
import { ReactComponent as BackOfficeIcon } from '../../../assets/sidebar/back-office_icon.svg';
import { ReactComponent as ControllerIcon } from '../../../assets/sidebar/controller_icon.svg';
import { ReactComponent as DraftsIcon } from '../../../assets/sidebar/drafts_icon.svg';
import { ReactComponent as FutureMeetingIcon } from '../../../assets/sidebar/future-meeting_icon.svg';
import { ReactComponent as HelpCenterIcon } from '../../../assets/sidebar/help-center_icon.svg';
import { ReactComponent as MeetingHistoryIcon } from '../../../assets/sidebar/meeting-history_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/sidebar/settings_icon.svg';
import { ReactComponent as TutorialIcon } from '../../../assets/sidebar/tutorial_icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/sidebar/upload_icon.svg';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalRouting,
    useGlobalSearchShortcut,
    useSidebar,
    useStripeUpgradeInAppEnablement,
} from '../../../hooks';
import { useGlobalDrafts } from '../../../hooks/useGlobalDrafts';
import { lightTheme } from '../../../styles';
import { URLUtil, isWebPlatform } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { ClientPath, Column, Hairline, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardSelectorIcons } from './DashboardSelectorIcons';
import { ProfileComponent } from './NavBar';
import { ShowcaseGuide } from './ShowcaseGuide';
import { DashboardComponent } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

function SearchBox(): JSX.Element {
    const { setIsSearchVisible, isSearchVisible } = useGlobalAiDashboard();
    const { isMac } = useGlobalSearchShortcut('');
    const track = useExperienceTracking();

    return (
        <Box
            style={{
                padding: 5,
                cursor: 'pointer',
                marginBottom: '20px',
                border: '1px solid rgba(160, 160, 160, 0.3)',
                minWidth: '60%',
                width: 'calc(100% - 10px)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: 'white',
                transition: 'background-color 0.2s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
            }}
            onClick={() => {
                setIsSearchVisible(!isSearchVisible);
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Search Meeting Notes in Sidebar',
                });
            }}
        >
            <SearchIcon style={{ color: '#A0A0A0' }} />
            <span style={{ color: 'gray', marginLeft: 5 }}>
                {`Search notes `}
                {isMac ? `(⌘ + K)` : `(Ctrl + K)`}
            </span>
        </Box>
    );
}

interface NavBarButtonProps {
    title: Omit<ReactNode, 'number' | 'boolean' | 'null' | 'undefined'>;
    section?: DashboardSection;
    activeSection?: DashboardSection;
    onClick: () => void;
    endIcon?: JSX.Element;
    startIcon: JSX.Element;
}

function NavBarButton({ title, section, activeSection, onClick, endIcon, startIcon }: NavBarButtonProps): JSX.Element {
    return (
        <Row>
            <Row
                style={{
                    marginTop: '1px',
                    marginBottom: '1px',
                    borderRadius: '8px',
                    padding: '5px 8px',
                    width: 'calc(100% - 7px)',
                    backgroundColor: section && activeSection === section ? '#DBEAE7' : 'transparent',
                    transition: 'background-color 0.2s ease',
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                onMouseOver={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'rgba(219, 234, 231, 0.6)';
                    }
                }}
                onMouseOut={(e) => {
                    if (!(section && activeSection === section)) {
                        e.currentTarget.style.backgroundColor = 'transparent';
                    }
                }}
                onClick={onClick}
            >
                {startIcon}
                <SecondaryButton
                    title={title}
                    buttonStyles={{ textDecoration: 'none', display: 'flex' }}
                    labelStyles={{
                        fontSize: '15px',
                        fontWeight: 600,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={lightTheme.primary.midnight}
                    onClick={onClick}
                    endIcon={endIcon}
                />
            </Row>
        </Row>
    );
}

export function Sidebar({
    activeSection,
    setActiveSection,
    setIsAddingAdditionalMeetings,
    shouldShowShowcaseGuide,
}: DashboardComponent & {
    setIsAddingAdditionalMeetings: (val: boolean) => void;
    shouldShowShowcaseGuide: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [drafts] = useGlobalDrafts();
    const track = useExperienceTracking();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const { routeToControl } = useGlobalRouting();
    const { fullSidebarWidth, userShouldSeeBothApps, appBarWidth, sidebarWidth, isDesktopView } = useSidebar();

    return (
        <Row
            style={{
                width: isDesktopView ? fullSidebarWidth : '100%',
                height: '100%',
                marginRight: '20px',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <DashboardSelectorIcons />
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: isDesktopView ? `${sidebarWidth}px` : '100%',
                    backgroundColor: `#ECF1F0`,
                    zIndex: 1,
                    position: 'relative',
                }}
            >
                <SidebarColumn>
                    {isDesktopView ? (
                        <SpinachLogo
                            style={{
                                height: isDesktopView ? '35px' : '25px',
                                minHeight: isDesktopView ? '35px' : '25px',
                                cursor: 'pointer',
                                marginLeft: '-35px',
                                marginBottom: '20px',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                    ) : null}
                    {isDesktopView ? (
                        <>
                            <ProfileComponent />
                            <Spacing factor={1 / 2} />
                        </>
                    ) : null}

                    {isDesktopView ? <SearchBox /> : null}

                    <NavBarButton
                        title="Meeting History"
                        startIcon={
                            <MeetingHistoryIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                        }
                        section={DashboardSection.Summaries}
                        activeSection={
                            isSummariesAndTranscriptsSection(activeSection) ? DashboardSection.Summaries : undefined
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                            setActiveSection(DashboardSection.Summaries);
                        }}
                    />
                    <NavBarButton
                        title="Future Meetings"
                        startIcon={<FutureMeetingIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        section={DashboardSection.Meetings}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                            setActiveSection(DashboardSection.Meetings);
                            setIsAddingAdditionalMeetings(false);
                        }}
                    />

                    {user.isAskSpinachPageEnabled ? (
                        <>
                            <NavBarButton
                                title="Ask Spinach"
                                startIcon={
                                    <AskSpinachIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                section={DashboardSection.AskSpinachPage}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Ask Spinach Page' });
                                    setActiveSection(DashboardSection.AskSpinachPage);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForDraftsSection ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <DraftsIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                title={
                                    <Row vCenter>
                                        Drafts{' '}
                                        {!!drafts?.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: '6px',
                                                    color: 'white',
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '50%',
                                                    fontSize:
                                                        drafts.length < 10
                                                            ? '16px'
                                                            : drafts.length < 100
                                                            ? '14px'
                                                            : '10px',
                                                    backgroundColor: lightTheme.primary.greenLight,
                                                }}
                                            >
                                                {drafts.length > 99 ? '99+' : drafts.length}
                                            </div>
                                        ) : null}
                                    </Row>
                                }
                                section={DashboardSection.Drafts}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Drafts Tab',
                                        NumberOfDrafts: drafts?.length,
                                    });
                                    setActiveSection(DashboardSection.Drafts);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForFileUpload ? (
                        <NavBarButton
                            startIcon={<UploadIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                            title="Upload"
                            section={DashboardSection.Upload}
                            activeSection={activeSection}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Upload Tab' });
                                setActiveSection(DashboardSection.Upload);
                            }}
                        />
                    ) : null}
                    {user.isEnabledForControlInNavBar ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <ControllerIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                title="Agenda Controls"
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Open Agenda Controls from Navbar',
                                    });
                                    if (isWebPlatform()) {
                                        URLUtil.openURLPopUp(`${getWebAppHost()}${ClientPath.Control}`);
                                    } else {
                                        routeToControl();
                                    }
                                }}
                                endIcon={
                                    isWebPlatform() ? (
                                        <CallMade
                                            style={{ fontSize: '16px', marginLeft: '5px' }}
                                            htmlColor={'#0000009c'}
                                        />
                                    ) : undefined
                                }
                            />
                        </>
                    ) : null}
                    <Hairline style={{ width: '70%' }} factor={1 / 2} />
                    <NavBarButton
                        startIcon={<SettingsIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Settings"
                        section={DashboardSection.Integrations}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                            setActiveSection(DashboardSection.Integrations);
                        }}
                    />

                    {user.isEnabledForEmbeddedWebflow ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <TutorialIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                title={user.embeddedWebflowCopy}
                                section={DashboardSection.Guides}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Embedded Webflow Tab' });
                                    setActiveSection(DashboardSection.Guides);
                                }}
                            />
                        </>
                    ) : null}

                    {isStripeUpgradeInAppEnabled ? (
                        <>
                            <NavBarButton
                                startIcon={
                                    <AccountIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                title="Account"
                                section={DashboardSection.Account}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Account Tab' });
                                    setActiveSection(DashboardSection.Account);
                                }}
                            />
                        </>
                    ) : null}

                    {user.hasAdminSectionAccess ? (
                        <>
                            <NavBarButton
                                startIcon={<AdminIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                                title="Admin"
                                section={DashboardSection.Admin}
                                activeSection={activeSection}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Admin Tab' });
                                    setActiveSection(DashboardSection.Admin);
                                }}
                            />
                        </>
                    ) : null}

                    <NavBarButton
                        startIcon={<HelpCenterIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Help Center"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL'));
                        }}
                        endIcon={<CallMade style={{ fontSize: '16px', marginLeft: '5px' }} htmlColor={'#0000009c'} />}
                    />

                    {isProductionStage() ? null : (
                        <>
                            <NavBarButton
                                startIcon={
                                    <BackOfficeIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                                }
                                title="Backoffice"
                                section={DashboardSection.Backoffice}
                                activeSection={activeSection}
                                onClick={() => {
                                    setActiveSection(DashboardSection.Backoffice);
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Backoffice Tab' });
                                }}
                            />
                        </>
                    )}
                </SidebarColumn>
                {shouldShowShowcaseGuide ? (
                    <div style={{ padding: '12px' }}>
                        <ShowcaseGuide />
                    </div>
                ) : null}
            </Column>
        </Row>
    );
}
