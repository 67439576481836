import { Box, CircularProgress, Divider, IconButton, TextField } from '@material-ui/core';
import { InfoOutlined, Lock } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import { Autocomplete } from '@material-ui/lab';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { demoBotId, getDemoData } from '@spinach-shared/constants';
import {
    AiHistoryUserAccessKind,
    ClientAiHistory,
    ClientEventType,
    ClientUsersWithAiHistoryAccess,
    Media,
    MeetingSelection,
    SummaryJson,
    TranscriptData,
    WebUrlQuery,
} from '@spinach-shared/types';
import { formatTime, getDemoSelection } from '@spinach-shared/utils';

import {
    getAiSummaryJson,
    getAskSpinachRealtimeConfig,
    getUserEmailsWithHistoryAccess,
    patchUser,
} from '../../../../apis';
import { getAiHistories, getAiSummaryHtml, getAiTranscript } from '../../../../apis/getAiHistories';
import { getMedia } from '../../../../apis/getMedia';
import { GlobalModal } from '../../../../atoms';
import { ElementId } from '../../../../constants';
import {
    useAskSpinachEnabledQueries,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    usePrevious,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ButtonProps, SetValue } from '../../../../types';
import { URLUtil, copyTextToClipboard, withContentMasking } from '../../../../utils';
import { Anchor, Badge, BootstrapTooltip, Column, Hairline, Row, Spacing } from '../../../common';
import { InformativeBanner } from '../../../common/InformativeBanner';
import { ConfirmDeleteMeetingModal } from '../../../common/modals';
import { ScrollArea } from '../../../stand-up';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import '../meetings/MeetingSection.css';
import { ActionsSectionContainer } from './ActionsSection';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import { MeetingMedia } from './MediaComponent';
import { MeetingChapters } from './MeetingChapters';
import { ShareSummaryRow } from './ShareSummaryRow';

const SELECTION_CODE_DELIMITER = '::';

const CustomScrollArea = styled(ScrollArea)`
    padding: unset;
    scrollbar-width: none;
`;

const SummaryContainer = styled.div<{ stackLayout: boolean }>`
    font-family: 'Gilroy';
    max-width: ${({ stackLayout }) => (stackLayout ? 'calc(100% - 20px)' : '650px')};
    margin-bottom: 20px;

    /* Create sections between h3 elements */
    h3 {
        background-color: #f5f5f5;
        padding: 20px 20px 0 20px;
        padding-bottom: 0px;
        margin: 24px 0 0 0;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
    }

    /* Container for h3 and its content */
    h3 + * {
        background-color: #f5f5f5;
        padding: 20px;
        padding-bottom: 20px;
        margin-top: 0;
        border-radius: 0 0 8px 8px;
    }

    /* Handle case where there might be multiple elements after h3 before next h3 */
    h3 ~ *:not(h3):not(h3 + *) {
        background-color: #f5f5f5;
        padding: 20px;
        margin-top: 0;
        border-radius: 0;
    }

    /* First level ul only */
    > ul {
        list-style-type: none;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;
    }

    /* Nested uls should not have padding */
    ul ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        line-height: 1.4;
        margin-bottom: 12px;
        padding-left: 0;
    }

    li:last-child {
        margin-bottom: 0;
    }

    /* Style the first h3 to remove top margin */
    h3:first-child {
        margin-top: 0;
    }

    /* Add spacing between sections */
    h3:not(:first-child) {
        margin-top: 24px;
    }

    /* Style b elements that are not within li elements */
    b:not(li b) {
        display: flex;
        flex: 1;
        background-color: #f5f5f5;
        padding: 2px 20px;
        padding-bottom: 0px;
        margin-bottom: -10px;
    }

    b:not(li b):first-of-type {
        padding-top: 20px;
    }
    br {
        display: none;
    }
`;

export const getBlurbId = (index: number) => `blurb-${index}`;

export function getHistorySelectionCode(seriesId: string, botId: string): string {
    return `${seriesId}${SELECTION_CODE_DELIMITER}${botId}`;
}

function getHistorySelectionCodeComponents(historySelectionCode: string): { seriesId: string; botId: string } {
    const [seriesId, botId] = historySelectionCode.split(SELECTION_CODE_DELIMITER);
    return { seriesId, botId };
}

const useFetchUsersWithHistoryAccess = ({
    botId,
}: {
    botId?: string;
}): [ClientUsersWithAiHistoryAccess[] | null, SetValue<ClientUsersWithAiHistoryAccess[] | null>] => {
    const [usersWithHistoryAccess, setUsersWithHistoryAccess] = useState<ClientUsersWithAiHistoryAccess[] | null>(null);
    const [globalModal] = useGlobalModal();
    const prevGlobalModal = usePrevious(globalModal);
    const prevBotId = usePrevious(botId);
    const [user] = useGlobalAuthedUser();
    useEffect(() => {
        const fetch = async (botId: string) => {
            // the demo meeting is hardcoded so there is nothing to fetch
            if (botId === demoBotId) {
                setUsersWithHistoryAccess([
                    {
                        email: user.email,
                        kind: AiHistoryUserAccessKind.Shared,
                    },
                ]);
                return;
            }
            const usersWithHistoryAccessResponse = await getUserEmailsWithHistoryAccess({ botId });
            if (usersWithHistoryAccessResponse?.usersWithHistoryAccess) {
                setUsersWithHistoryAccess(usersWithHistoryAccessResponse.usersWithHistoryAccess);
            } else {
                setUsersWithHistoryAccess(null);
            }
        };
        if (botId && (botId !== prevBotId || (prevGlobalModal === GlobalModal.ShareAiHistory && !globalModal))) {
            fetch(botId);
        }
    }, [globalModal, botId]);
    return [usersWithHistoryAccess, setUsersWithHistoryAccess];
};

// Add this type near the top of the file
type VideoMode = 'full' | 'highlights';

export function TranscriptsSection(): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [searchParams, setSearchParams] = useSearchParams();
    const { width, height, isDesktopView } = useWindowSize();
    const {
        activeSection,
        setActiveSection,
        setToastText,
        setIsOnDemoNotes,
        meetingOptions: options,
        setMeetingOptions: setOptions,
        state: { pendingBotId, isLoadingPendingBot },
        setState,
    } = useGlobalAiDashboard();

    const [results, setResults] = useState<TranscriptData | null>(null);
    const [minimalTranscriptResponse, setMinimalTranscriptResponse] = useState<string[] | null>(null);
    const [selection, setSelection] = useState<MeetingSelection | null>(null);
    const [media, setMedia] = useState<Media>();

    const HTML_THAT_BREAKS_NEW_STYLE =
        "<i>Say </i><b>Hey Spinach</b><i> during your meeting. Here's what we heard:</i>";

    const [summaryHtml, setSummaryHtml] = useState('');
    const cleanSummaryHtml = React.useMemo(
        () =>
            DOMPurify.sanitize(summaryHtml.replace(HTML_THAT_BREAKS_NEW_STYLE, ''), {
                USE_PROFILES: { html: true },
                ADD_ATTR: ['target'],
            }),
        [summaryHtml]
    );
    const [summaryJson, setSummaryJson] = useState<SummaryJson | null>(null);

    const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
    const [isLoadingHistories, setIsLoadingHistories] = useState(false);
    const [isLoadingSummaryJson, setIsLoadingSummaryJson] = useState(false);

    const shouldShowTicketSuggestions = user.isActionItemTicketCreationEnabled;
    const isMeetingChaptersVisibleOnDashboard = user.isMeetingChaptersVisibleOnDashboard;

    const isLoading =
        isLoadingHistories || isLoadingTranscript || (isLoadingSummaryJson && shouldShowTicketSuggestions);

    const hasProFeatures = useProFeatures();
    const freeOptionsCodes = options.slice(0, 3).map((option) => option.code);
    const unlimitedMeetingHistoryForFreeUsers = user.isEnabledForUnlimitedMeetingHistoryForFreeUsers;
    const isSelectedTranscriptLimited =
        !hasProFeatures &&
        selection &&
        !freeOptionsCodes.includes(selection.code) &&
        !unlimitedMeetingHistoryForFreeUsers;
    const navigateToUpgrade = useNavigateToUpgrade();
    const [botId, setBotId] = useState<string | undefined>(undefined);
    const [seriesId, setSeriesId] = useState<string | undefined>(undefined);

    const [usersWithHistoryAccess] = useFetchUsersWithHistoryAccess({ botId });

    // Add new state for video mode
    const [videoMode, setVideoMode] = useState<VideoMode>('highlights');

    async function fetchSelectedResults(selection: { code: string; label: string }) {
        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);
        await fetchSelectedResultsByIds(seriesId, botId);
    }

    async function fetchSelectedResultsByIds(seriesId: string, botId: string) {
        setIsLoadingTranscript(true);
        setIsLoadingSummaryJson(true);
        setMedia({ loading: true });
        setSummaryJson(null);
        setResults(null);
        setSummaryHtml('');
        setMinimalTranscriptResponse(null);
        setSecond(0);
        const isDemo = botId === demoBotId;
        setIsOnDemoNotes(isDemo);
        const [transcriptResponse, askSpinachConfig, summaryHtmlResponse, summaryJsonResponse, media] = isDemo
            ? getDemoData({ username: user.firstName })
            : await Promise.all([
                  getAiTranscript({ seriesId, botId }),
                  getAskSpinachRealtimeConfig({ seriesId, botId }),
                  getAiSummaryHtml({ seriesId, botId }),
                  shouldShowTicketSuggestions || isMeetingChaptersVisibleOnDashboard
                      ? getAiSummaryJson(botId, false)
                      : undefined,
                  getMedia({ botId }),
              ]);
        setIsLoadingTranscript(false);
        setIsLoadingSummaryJson(false);
        setBotId(botId);
        setSeriesId(seriesId);
        if (summaryJsonResponse && 'summaryJson' in summaryJsonResponse) {
            setSummaryJson(summaryJsonResponse.summaryJson);
        } else {
            setSummaryJson(null);
        }

        if (askSpinachConfig?.minimalTranscript) {
            setMinimalTranscriptResponse(askSpinachConfig.minimalTranscript);
        } else {
            setMinimalTranscriptResponse(null);
        }

        if (transcriptResponse) {
            setResults(transcriptResponse);
        } else {
            setResults(null);
        }

        if (summaryHtmlResponse) {
            setSummaryHtml(summaryHtmlResponse);
        } else {
            setSummaryHtml('');
        }

        if (media) {
            setMedia(media);
        } else {
            setMedia({ error: true });
        }
    }

    function onDownloadVideo() {
        if (!hasProFeatures) {
            return;
        }
        if (!media || !('recordingUrl' in media)) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Download Video',
        });
        URLUtil.openURL(media.recordingUrl!);
    }

    function onCopyTranscript() {
        if (!selection) {
            return;
        }

        if (!results) {
            return;
        }
        if (results.isTranscriptDisabled) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(
            results.transcriptLineData
                .map((t) => `${formatTime(t.timestamp)} ${t.speaker}: ${t.statement}`)
                .join('\n\n')
        );
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Transcript Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Transcript copied to clipboard');
    }

    function onCopySummary() {
        if (!selection) {
            return;
        }

        if (!summaryHtml) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(summaryHtml);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Summary Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Summary copied to clipboard');
    }

    const readingSummaryLengthThresholdChars = 100;
    const readingSummaryMinimumTimeMs = 5000;
    useEffect(() => {
        if (
            activeSection === DashboardSection.Summaries &&
            results &&
            !results.isTranscriptDisabled &&
            results.transcriptLineData.length &&
            summaryHtml &&
            summaryHtml.length > readingSummaryLengthThresholdChars &&
            !user.metadata.hasReadAsummary
        ) {
            const timeout = setTimeout(async () => {
                const { user: updatedUser } = await patchUser({
                    metadata: { hasReadAsummary: true },
                });
                if (updatedUser) {
                    setUser(updatedUser);
                }
            }, readingSummaryMinimumTimeMs);
            return () => clearTimeout(timeout);
        }
    }, [activeSection, results, setUser, summaryHtml, user.metadata.hasReadAsummary]);

    useEffect(() => {
        if (
            selection?.isPartialCappedSummary &&
            !user.hasProFeatures &&
            activeSection !== DashboardSection.Chapters &&
            activeSection !== DashboardSection.Transcripts &&
            activeSection !== DashboardSection.AskSpinach
        ) {
            setActiveSection(DashboardSection.Chapters);
        }
    }, [activeSection, selection?.isPartialCappedSummary, setActiveSection, user.hasProFeatures]);

    const [lastFetchedIso, setLastFetchedIso] = useState<string | null>(new Date().toISOString());
    const shouldAllowFetching = useRef(true);

    useEffect(() => {
        async function exec() {
            let updateContentUponFetch = true;
            const deepSeriesId = searchParams.get(WebUrlQuery.SeriesId);
            const deepBotId = searchParams.get(WebUrlQuery.BotId);

            if (!deepSeriesId && !deepBotId && !shouldAllowFetching.current) {
                return;
            }

            shouldAllowFetching.current = false;

            setIsLoadingHistories(true);

            const mapHistoryToSelection = (h: ClientAiHistory): MeetingSelection => ({
                botId: h.botId,
                seriesId: h.seriesId,
                code: getHistorySelectionCode(h.seriesId, h.botId),
                label: `${h.meetingTitle}`,
                verbalDate: moment(h.customMeetingDate || h.createdAt).format('dddd, MMM Do YYYY'),
                title: h.meetingTitle,
                createdAt: new Date(h.customMeetingDate || h.createdAt),
                isPartialCappedSummary: h.isPartialCappedSummary,
                icpId: h.icpId,
                ticket: h.ticket,
                isEdited: h.isEdited,
                isEnabledForEditSummary: h.isEnabledForEditSummary,
            });

            if (deepSeriesId && deepBotId) {
                const initialHistory = await getAiHistories({ botId: deepBotId });
                if (initialHistory && initialHistory.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistory[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            } else if (!deepSeriesId && !deepBotId) {
                const initialHistory = await getAiHistories({ limit: 1 });
                if (initialHistory && initialHistory.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistory[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            }

            const histories = await getAiHistories();

            setIsLoadingHistories(false);

            if (!histories) {
                return;
            }

            const hasLimitedHistory = histories.length >= 1 && histories.length < 5;
            const forceDemo = searchParams.get('demo') === '1' || histories.length === 0;
            const mapped: MeetingSelection[] = histories.map(mapHistoryToSelection);

            // Add demo notes at the top if feature flag enabled or other conditions met
            if (user.isEnabledForPersistentDemoNotes || forceDemo || deepBotId === demoBotId || hasLimitedHistory) {
                if (hasLimitedHistory) {
                    mapped.push(getDemoSelection({ icpId: user._id }));
                } else if (mapped.length > 0 && user.isEnabledForPersistentDemoNotes) {
                    mapped.splice(1, 0, getDemoSelection({ icpId: user._id }));
                } else {
                    mapped.unshift(getDemoSelection({ icpId: user._id }));
                }
            }

            setOptions(mapped.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));

            if (forceDemo) {
                setSelection(getDemoSelection({ icpId: user._id }));
                await fetchSelectedResults(getDemoSelection({ icpId: user._id }));
                return;
            }

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Transcripts Loaded',
                TranscriptCount: mapped.length,
            });

            const deepSelection =
                !!deepBotId && !!deepSeriesId
                    ? mapped.find((option) => option.code === getHistorySelectionCode(deepSeriesId, deepBotId))
                    : null;

            const isUnpaidTranscript =
                deepSelection?.code &&
                !hasProFeatures &&
                !mapped
                    .slice(0, 3)
                    .map((option) => option.code)
                    .includes(deepSelection.code);

            if (isUnpaidTranscript && !user.isEnabledForUnlimitedMeetingHistoryForFreeUsers) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Limited Transcript',
                });
                setSelection(deepSelection);
                setResults(null);
            } else if (
                deepSelection &&
                deepSeriesId &&
                (!!user.getSeriesById(deepSeriesId) || user.hasAdminUserContentAccess)
            ) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Transcript',
                });

                if (updateContentUponFetch) {
                    setSelection(deepSelection);
                    await fetchSelectedResults(deepSelection);
                }
            } else if (mapped[0]) {
                if (updateContentUponFetch) {
                    setSelection(mapped[0]);
                    await fetchSelectedResults(mapped[0]);
                }
            }
            if (pendingBotId) {
                // fetch the history for that bot
                const uploadedHistory = await getAiHistories({ botId: pendingBotId });
                if (uploadedHistory && uploadedHistory.length > 0) {
                    const newSelection = mapHistoryToSelection(uploadedHistory[0]);
                    setSelection(newSelection);
                    await fetchSelectedResults(newSelection);
                } else {
                    setToastText('Failed to load the meeting summary. Please try again.');
                }
                setState((prev) => ({
                    ...prev,
                    pendingBotId: undefined,
                    isLoadingPendingBot: false,
                }));
            }
        }
        exec();
    }, [lastFetchedIso, searchParams.get(WebUrlQuery.SeriesId), searchParams.get(WebUrlQuery.BotId), pendingBotId]);

    // Clear the navigation state once used.
    useEffect(() => {
        if (selection && searchParams.get(WebUrlQuery.SeriesId)) {
            setSearchParams((searchParams) => {
                searchParams.delete(WebUrlQuery.SeriesId);
                searchParams.delete(WebUrlQuery.BotId);
                searchParams.delete(WebUrlQuery.EditSuccess);
                return searchParams;
            });
        }
    }, [searchParams, setSearchParams, selection]);

    const [currentSecond, setSecond] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const stackVideoWidth = width < 1200;
    const stackVideoLayout =
        stackVideoWidth || !('recordingUrl' in (media || {})) || 'isVideoRecordingDisabled' in (media || {});

    useEffect(() => {
        if (!stackVideoLayout && activeSection === DashboardSection.AskSpinach) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [stackVideoLayout, activeSection, setActiveSection]);

    useEffect(() => {
        if (
            selection &&
            summaryJson &&
            !summaryJson.meetingChapters?.length &&
            activeSection === DashboardSection.Chapters
        ) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [selection, summaryJson, activeSection, setActiveSection]);

    const askSpinachConfig = useAskSpinachEnabledQueries();
    const askSpinachEnabled = Object.keys(askSpinachConfig).length > 0;

    const lockedTabButtonProps: Partial<ButtonProps> = user.hasProFeatures
        ? {}
        : {
              preIcon: selection?.isPartialCappedSummary ? (
                  <Lock fontSize={'small'} htmlColor={lightTheme.tertiary.midnight} style={{ marginBottom: '-3px' }} />
              ) : undefined,
              disabled: !!selection?.isPartialCappedSummary,
          };

    const isShareAiHistoryEnabled = user.isShareAiHistoryEnabled;

    const isUnpublishedDraft = (option: MeetingSelection | null): boolean => {
        return !!option && !!option.isEnabledForEditSummary && !option.isEdited;
    };

    const getOptionLabelAndStatus = (option: MeetingSelection) => {
        const isLimited =
            !hasProFeatures && !freeOptionsCodes.includes(option.code) && !unlimitedMeetingHistoryForFreeUsers;

        return {
            label: isLimited ? `${option.label} (Spinach Pro Only)` : option.label,
            isLimited,
            isUnpublishedDraft: isUnpublishedDraft(option),
        };
    };

    const [headerHeightState, setHeaderHeightState] = useState(0);

    const headerElement = document.getElementById(ElementId.MeetingNotesOverviewHeader);
    useEffect(() => {
        const height = headerElement?.getBoundingClientRect().height ?? 0;
        setHeaderHeightState(height);
    }, [headerElement?.getBoundingClientRect().height]);

    const heightOfSubTabRow = 51;

    // Modify the click handler for transcript lines
    function onTranscriptClick(timestamp: number) {
        if (activeSection === DashboardSection.Transcripts) {
            setVideoMode('full');
            // Use setTimeout to ensure the video element is in DOM before trying to play
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = timestamp + 0.3;
                    videoRef.current.play();
                }
            }, 0);
        }
    }

    function onChapterClick(second: number) {
        if (activeSection === DashboardSection.Chapters) {
            setVideoMode('full');
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = second + 0.3;
                    videoRef.current.play();
                }
            }, 0);
        }
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" position="relative">
            {isLoadingPendingBot ? (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 1)"
                    zIndex={1000}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            <Box display="flex" flexDirection="column" id={ElementId.MeetingNotesOverviewHeader}>
                <Spacing factor={2 / 3} />
                <Row vCenter>
                    <Autocomplete
                        id="summaries-and-transcripts-id-selection"
                        options={options}
                        groupBy={(option) => moment(option.createdAt).format('YYYY/MM/DD')}
                        renderGroup={(params) => {
                            return (
                                <Column style={{ marginTop: '10px' }}>
                                    <BodyRegular
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '12px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        {moment(params.group).format('dddd, MMMM D').toUpperCase()}
                                    </BodyRegular>
                                    {params.children}
                                </Column>
                            );
                        }}
                        renderOption={(option) => {
                            const { label, isUnpublishedDraft } = getOptionLabelAndStatus(option);
                            return (
                                <Row vCenter style={{ width: 'calc(100% - 30px)' }}>
                                    <BodyRegularOnboard style={{ marginLeft: '10px' }}>{label}</BodyRegularOnboard>
                                    {isUnpublishedDraft ? (
                                        <span style={{ marginLeft: '10px' }}>
                                            <Badge title="Draft" />
                                        </span>
                                    ) : null}
                                    {option.botId === demoBotId ? (
                                        <span style={{ marginLeft: '10px' }}>
                                            <Badge title="Sample" />
                                        </span>
                                    ) : null}
                                </Row>
                            );
                        }}
                        getOptionLabel={(option: MeetingSelection) => getOptionLabelAndStatus(option).label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Choose a meeting"
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        fontWeight: 500,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        fontWeight: 500,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    },
                                    startAdornment: (
                                        <>
                                            {params.InputProps.startAdornment}
                                            {isUnpublishedDraft(selection) ? (
                                                <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                    <Badge title="Draft" />
                                                </span>
                                            ) : null}
                                            {selection?.botId === demoBotId ? (
                                                <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                    <Badge title="Sample" />
                                                </span>
                                            ) : null}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        value={selection}
                        size="small"
                        style={{
                            minWidth: isDesktopView ? undefined : '60%',
                            marginBottom: '10px',
                            width: isDesktopView && !stackVideoWidth ? '78%' : 'calc(95% - 50px)',
                            maxWidth: isDesktopView ? '1000px' : '600px',
                        }}
                        autoComplete
                        onChange={async (event, selection) => {
                            setSelection(selection);

                            if (!selection) {
                                setSelection(null);
                                setResults(null);
                                setSummaryHtml('');
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Cleared Transcript Selection',
                                });
                                return;
                            }

                            if (
                                !hasProFeatures &&
                                !freeOptionsCodes.includes(selection.code) &&
                                !unlimitedMeetingHistoryForFreeUsers
                            ) {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Selected Limited Transcript to Open',
                                });
                                setResults(null);
                                setSummaryHtml('');
                                return;
                            }

                            await fetchSelectedResults(selection);

                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Selected Transcript to Open',
                            });
                        }}
                        {...withContentMasking()}
                    />
                    <Column vCentered={true} style={{ width: '60px' }}>
                        <IconButton
                            onClick={async () => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Refresh Calendar Events',
                                });
                                shouldAllowFetching.current = true;
                                setLastFetchedIso(new Date().toISOString());
                            }}
                            style={{ marginTop: '-10px' }}
                            disableRipple
                            disabled={isLoading}
                            className={isLoading ? 'refresh-upcoming-meetings' : undefined}
                        >
                            <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                        </IconButton>
                    </Column>
                </Row>
                {selection?.botId === demoBotId ? (
                    <>
                        <InformativeBanner
                            message={
                                <span style={{ color: lightTheme.primary.green, fontWeight: 'bold', fontSize: '16px' }}>
                                    This is an example meeting
                                </span>
                            }
                        />
                        <Spacing factor={1 / 3} />
                    </>
                ) : null}
                {isUnpublishedDraft(selection) ? (
                    <Row
                        style={{
                            backgroundColor: '#EEF9F6',
                            borderRadius: '4px',
                            paddingLeft: '5px',
                            paddingBottom: '5px',
                            marginBottom: '10px',
                            maxWidth: '600px',
                        }}
                    >
                        <Row style={{ alignItems: 'center' }}>
                            <InfoOutlined style={{ color: '#4D9F8A', marginTop: '4px', marginRight: '10px' }} />
                            <BodyRegularOnboard style={{ fontWeight: 400, marginTop: '4px' }}>
                                This summary is an unpublished draft. Go to{' '}
                                <div style={{ display: 'inline-block' }}>
                                    <SecondaryButton
                                        title="Drafts"
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Go to Drafts from Histories',
                                            });
                                            setActiveSection(DashboardSection.Drafts);
                                        }}
                                    />
                                </div>{' '}
                                to edit and publish it.
                            </BodyRegularOnboard>
                        </Row>
                    </Row>
                ) : null}
            </Box>
            <Box
                display="flex"
                style={{ overflowY: stackVideoLayout && isDesktopView ? 'scroll' : 'initial' }}
                flexDirection={stackVideoLayout ? 'column' : 'row'}
                overflow="clip"
            >
                {botId && selection && ('recordingUrl' in (media || {}) || 'googleDriveUrl' in (media || {})) ? (
                    <MeetingMedia
                        media={media}
                        stackVideoLayout={stackVideoLayout}
                        onDownloadVideo={onDownloadVideo}
                        meetingSelection={selection}
                        freeOptionsCodes={freeOptionsCodes}
                        videoRef={videoRef}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        transcript={results ?? undefined}
                        dashboardSection={activeSection}
                        onTimeUpdate={(timestamp) => setSecond(timestamp)}
                        botId={botId}
                        seriesId={seriesId}
                        onTitleUpdate={(updatedSelection) => setSelection(updatedSelection)}
                        summaryJson={summaryJson}
                        videoMode={videoMode}
                        setVideoMode={setVideoMode}
                    />
                ) : (
                    <></>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={stackVideoLayout ? undefined : 6}
                    mt={stackVideoLayout ? '10px' : '0px'}
                    overflow="clip"
                >
                    <CustomScrollArea
                        horizontal
                        style={{
                            flexDirection: 'row',
                            minWidth: 'calc(60% + 40px)',
                        }}
                    >
                        {(shouldShowTicketSuggestions && summaryJson) ||
                        (selection?.isPartialCappedSummary && !user.hasProFeatures) ? (
                            <BootstrapTooltip title={lockedTabButtonProps.disabled ? 'Upgrade to access' : ''}>
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    <SecondaryButton
                                        title="Actions"
                                        buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                        color={
                                            activeSection === DashboardSection.Actions
                                                ? lightTheme.primary.greenLight
                                                : lightTheme.primary.midnight
                                        }
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Action Tab' });
                                            setActiveSection(DashboardSection.Actions);
                                        }}
                                        {...lockedTabButtonProps}
                                    />
                                </Box>
                            </BootstrapTooltip>
                        ) : (
                            <></>
                        )}
                        <BootstrapTooltip title={lockedTabButtonProps.disabled ? 'Upgrade to access' : ''}>
                            <Box style={{ whiteSpace: 'nowrap' }}>
                                <SecondaryButton
                                    title="Summary"
                                    buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                    color={
                                        activeSection === DashboardSection.Summaries
                                            ? lightTheme.primary.greenLight
                                            : lightTheme.primary.midnight
                                    }
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summary Tab' });
                                        setActiveSection(DashboardSection.Summaries);
                                    }}
                                    {...lockedTabButtonProps}
                                />
                            </Box>
                        </BootstrapTooltip>
                        {(isMeetingChaptersVisibleOnDashboard || selection?.isPartialCappedSummary) &&
                        summaryJson?.meetingChapters?.length ? (
                            <Box style={{ whiteSpace: 'nowrap' }}>
                                <SecondaryButton
                                    title="Chapters"
                                    buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                    color={
                                        activeSection === DashboardSection.Chapters
                                            ? lightTheme.primary.greenLight
                                            : lightTheme.primary.midnight
                                    }
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Chapters Tab',
                                        });
                                        setActiveSection(DashboardSection.Chapters);
                                    }}
                                />
                            </Box>
                        ) : null}
                        {!!results && !results.isTranscriptDisabled && results.transcriptLineData.length ? (
                            <>
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    <SecondaryButton
                                        title="Transcript"
                                        buttonStyles={{ textDecoration: 'none' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                        color={
                                            activeSection === DashboardSection.Transcripts
                                                ? lightTheme.primary.greenLight
                                                : lightTheme.primary.midnight
                                        }
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Transcript Tab',
                                            });
                                            setActiveSection(DashboardSection.Transcripts);
                                        }}
                                    />
                                </Box>

                                {stackVideoLayout && askSpinachEnabled ? (
                                    <Box style={{ whiteSpace: 'nowrap' }}>
                                        <SecondaryButton
                                            title="Ask Spinach"
                                            buttonStyles={{ textDecoration: 'none', marginLeft: '30px' }}
                                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                            color={
                                                activeSection === DashboardSection.AskSpinach
                                                    ? lightTheme.primary.greenLight
                                                    : lightTheme.primary.midnight
                                            }
                                            onClick={() => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Ask Spinach Tab',
                                                });
                                                setActiveSection(DashboardSection.AskSpinach);
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : null}
                        <Spacing factor={1 / 2} horizontal />
                        {botId && seriesId && usersWithHistoryAccess && isShareAiHistoryEnabled && selection ? (
                            <ShareSummaryRow
                                seriesId={seriesId}
                                meetingTitle={selection.title}
                                hostId={selection.icpId}
                                onCopyTranscript={onCopyTranscript}
                                shouldShowCopyTranscript={Boolean(
                                    results && !results.isTranscriptDisabled && results.transcriptLineData.length
                                )}
                                shouldShowDownloadVideo={Boolean(
                                    media && 'recordingUrl' in media && !!media.recordingUrl
                                )}
                                onCopySummary={onCopySummary}
                                botId={botId}
                                usersWithHistoryAccess={usersWithHistoryAccess}
                                onDownloadVideo={onDownloadVideo}
                            />
                        ) : (
                            <></>
                        )}
                        <span style={{ width: '40px' }} />
                    </CustomScrollArea>

                    <Divider style={{ marginTop: '10px' }} />
                    <Box flex={1} display="flex" flexDirection="column" overflow="clip">
                        {(!!results && Array.isArray(results) && results.length) || !!summaryHtml ? (
                            <>
                                <ScrollArea
                                    sidePadding={0}
                                    style={{
                                        paddingTop: '10px',
                                        width: '100%',
                                        overflowY: isDesktopView && !stackVideoLayout ? 'scroll' : 'unset',
                                        height:
                                            isDesktopView && !stackVideoLayout
                                                ? `${height - headerHeightState - heightOfSubTabRow}px`
                                                : 'unset',
                                    }}
                                >
                                    {activeSection === DashboardSection.Actions && selection ? (
                                        <ActionsSectionContainer
                                            summaryJson={summaryJson}
                                            setSummaryJson={setSummaryJson}
                                            botId={getHistorySelectionCodeComponents(selection.code).botId}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {botId && activeSection === DashboardSection.AskSpinach ? (
                                        <AskSpinach
                                            from={AskSpinachFrom.MeetingHistory}
                                            botId={botId}
                                            minimalTranscriptResponse={minimalTranscriptResponse}
                                            seriesId={seriesId}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {activeSection === DashboardSection.Summaries ? (
                                        <Column>
                                            {cleanSummaryHtml ? (
                                                <>
                                                    <SummaryContainer
                                                        stackLayout={stackVideoLayout}
                                                        {...withContentMasking()}
                                                        dangerouslySetInnerHTML={{ __html: cleanSummaryHtml }}
                                                    />
                                                </>
                                            ) : (
                                                <Row>
                                                    <BodyRegular>No summary found for this meeting.</BodyRegular>
                                                </Row>
                                            )}
                                        </Column>
                                    ) : null}
                                    {activeSection === DashboardSection.Transcripts ? (
                                        !results?.isTranscriptDisabled && results?.transcriptLineData ? (
                                            results.transcriptLineData.map((blurb, i) => {
                                                const isActive =
                                                    currentSecond >= blurb.timestamp &&
                                                    (results.transcriptLineData[i + 1] == undefined ||
                                                        currentSecond <= results.transcriptLineData[i + 1].timestamp);
                                                return (
                                                    <Column
                                                        key={i}
                                                        onClick={() => {
                                                            onTranscriptClick(blurb.timestamp);
                                                        }}
                                                        id={getBlurbId(i)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginBottom: '10px',
                                                            background: isActive
                                                                ? 'rgba(53, 162, 137, 0.5)'
                                                                : undefined,
                                                        }}
                                                    >
                                                        <Row>
                                                            <span style={{ color: 'gray', minWidth: '45px' }}>
                                                                {formatTime(blurb.timestamp)}
                                                            </span>
                                                            <b
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    minWidth: '150px',
                                                                    marginRight: '5px',
                                                                }}
                                                                {...withContentMasking()}
                                                            >
                                                                {blurb.speaker}
                                                            </b>
                                                        </Row>
                                                        <Row
                                                            style={{ marginLeft: '50px', width: 'calc(100% -  50px)' }}
                                                            {...withContentMasking()}
                                                        >
                                                            {blurb.statement}
                                                        </Row>
                                                    </Column>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )
                                    ) : null}
                                    {activeSection === DashboardSection.Chapters ? (
                                        <MeetingChapters
                                            botId={botId}
                                            seriesId={seriesId}
                                            chapters={summaryJson?.meetingChapters}
                                            playAtSecond={(second) => {
                                                onChapterClick(second);
                                            }}
                                            currentTime={
                                                videoRef.current?.paused || videoRef.current?.ended
                                                    ? undefined
                                                    : currentSecond
                                            }
                                        />
                                    ) : null}
                                </ScrollArea>
                            </>
                        ) : !options.length && !isLoadingHistories ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>No summaries or transcripts available.</BodyRegular>
                                </Row>
                            </>
                        ) : isSelectedTranscriptLimited ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>
                                        You can access older summaries and transcripts by{' '}
                                        <Anchor
                                            onClick={() => {
                                                navigateToUpgrade('Click Upgrade (Opened Limited Transcript)');
                                            }}
                                        >
                                            Upgrading
                                        </Anchor>
                                        .
                                    </BodyRegular>
                                </Row>
                            </>
                        ) : (!results || (Array.isArray(results) && !results.length)) &&
                          !isLoadingTranscript &&
                          !!options.length &&
                          !!selection ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>No summary or transcript found for this meeting.</BodyRegular>
                                </Row>
                            </>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            {selection ? <ConfirmDeleteMeetingModal selection={selection} /> : null}
        </Box>
    );
}
