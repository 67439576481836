import React from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { StoredSpinachSeries, isProductionStage } from '@spinach-shared/utils';

import { useGlobalAiDashboard, useGlobalAuthedUser } from '../../../hooks';
import { useSidebar } from '../../../hooks/useSidebar';
import { Backoffice } from '../../backoffice/Backoffice';
import { Column } from '../../common';
import { AccountSection } from './AccountManagement/AccountSection';
import { AdminSection } from './admin';
import { DraftsSection } from './drafts/DraftsSection';
import { EmbeddedWebflowSection } from './embedded-webflow/EmbeddedWebflowSection';
import { IntegrationsSection } from './integrations';
import { MeetingSectionWithoutCalendarIntegration } from './meetings';
import { CombinedMeetingSection } from './meetings/CombinedMeetingSection';
import { TranscriptsSection } from './summaries';
import { AskSpinachPageSection } from './summaries/AskSpinachPageSection';
import { UploadSection } from './summaries/UploadSection';
import { ActiveSectionComponent } from './types';

export function ActiveSection({
    activeSection,
    setModalKind,
    storedSeriesList,
    isAddingAdditionalMeetings,
    setIsAddingAdditionalMeetings,
    setStoredSeriesList,
    setModalMetadata,
}: ActiveSectionComponent & {
    isAddingAdditionalMeetings: boolean;
    setIsAddingAdditionalMeetings: (value: boolean) => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
}): JSX.Element {
    let component: JSX.Element;
    const [user] = useGlobalAuthedUser();
    const {
        state: { lastRefreshedHistory },
    } = useGlobalAiDashboard();
    const { fullSidebarWidth, isDesktopView } = useSidebar();

    switch (activeSection) {
        case DashboardSection.Meetings:
            component = user.isAuthedForAnyCalendar ? (
                <CombinedMeetingSection
                    storedSeriesList={storedSeriesList}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            ) : (
                <MeetingSectionWithoutCalendarIntegration
                    storedSeriesList={storedSeriesList.filter((s) => s.isScribeSeriesVisible)}
                    isAddingAdditionalMeetings={isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            );
            break;
        case DashboardSection.Integrations:
            component = <IntegrationsSection setModalKind={setModalKind} setModalMetadata={setModalMetadata} />;
            break;
        case DashboardSection.Transcripts:
        case DashboardSection.Summaries:
        case DashboardSection.Actions:
        case DashboardSection.Chapters:
        case DashboardSection.AskSpinach:
            component = <TranscriptsSection key={lastRefreshedHistory} />;
            break;
        case DashboardSection.Account:
            component = <AccountSection />;
            break;
        case DashboardSection.Admin:
            component = <AdminSection />;
            break;
        case DashboardSection.AskSpinachPage:
            component = <AskSpinachPageSection />;
            break;
        case DashboardSection.Upload:
            component = <UploadSection />;
            break;
        case DashboardSection.Drafts:
            component = <DraftsSection />;
            break;
        case DashboardSection.Guides:
            component = <EmbeddedWebflowSection />;
            break;
        // we want to fall through in case this is production
        // @ts-expect-error
        case DashboardSection.Backoffice:
            if (!isProductionStage()) {
                component = <Backoffice />;
                break;
            }
        default:
            component = user.isAuthedForAnyCalendar ? (
                <CombinedMeetingSection
                    storedSeriesList={storedSeriesList}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            ) : (
                <MeetingSectionWithoutCalendarIntegration
                    storedSeriesList={storedSeriesList}
                    isAddingAdditionalMeetings={isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            );
            break;
    }
    return (
        <Column
            style={{
                height: '100%',
                width: isDesktopView ? `calc(100% - ${fullSidebarWidth + 20}px)` : '100%',
            }}
        >
            {component}
        </Column>
    );
}
