import { DashboardSection } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { isTicketSourceWithSubProject } from '@spinach-shared/utils';

import { CreateTicketIntegrationRedirectCause } from '../../../types';

export function isSummariesAndTranscriptsSection(activeSection: DashboardSection): boolean {
    return (
        activeSection === DashboardSection.Transcripts ||
        activeSection === DashboardSection.Summaries ||
        activeSection === DashboardSection.Actions ||
        activeSection === DashboardSection.AskSpinach ||
        activeSection === DashboardSection.Chapters
    );
}

export function getCreateTicketIntegrationRedirectCause(user: ClientUser) {
    const isNotIntegratedWithAnyTicketSource = !user.authedTicketSources.length;
    const isIntegratedWithTicketSourceButNoProjectSelected =
        !!user.authedValidTicketCreationSources.length &&
        /**
         * @NOTE Logic her is as follows
         * The user is integrated with some ticket source (as checked above) but
         * the user has no ticket source project selected (check #1) OR the user
         * does have a ticket source project selected (implicit in the failed first check)
         * but the ticket source is a ticket source which requires a sub project (check #2.a)
         * and the user has no sub project selected (check #2.b)
         *
         * psudeo code:
         * noTicketProjectSelected OR (isTicketSourceWithSubProject AND noSubProjectSelected)
         * */
        user.authedValidTicketCreationSources.every(
            (source) =>
                !user.ticketSourceProject(source) ||
                (!!isTicketSourceWithSubProject(source) && !user.ticketSourceSubProject(source))
        );
    const isAuthedWithJiraWithNoCreateScopes =
        user.isAuthedForJira && !user.integrationSettings?.jiraSettings?.hasCreateScopes;

    let integrationRedirectCause: CreateTicketIntegrationRedirectCause | null = null;
    if (isNotIntegratedWithAnyTicketSource) {
        integrationRedirectCause = CreateTicketIntegrationRedirectCause.ConnectTicketSource;
    } else if (isIntegratedWithTicketSourceButNoProjectSelected) {
        integrationRedirectCause = CreateTicketIntegrationRedirectCause.SelectProject;
    } else if (isAuthedWithJiraWithNoCreateScopes) {
        integrationRedirectCause = CreateTicketIntegrationRedirectCause.UpgradeJiraIntegration;
    }

    return {
        isNotIntegratedWithAnyTicketSource,
        isIntegratedWithTicketSourceButNoProjectSelected,
        isAuthedWithJiraWithNoCreateScopes,
        cause: integrationRedirectCause,
    };
}
